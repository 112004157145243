<template>
  <div
    id="page"
    style="width: 100vw; height: 100vh; overflow-y: hidden"
  ></div>
</template>
<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import axios from '@axios'
import LoopPages from './utils/LoopPages'

require('grapesjs/dist/css/grapes.min.css')

export default {
  data() {
    return {
      token: null,
      displayData: null,
      retrieveDataInterval: null,
      loopPages: null,
    }
  },
  async mounted() {
    const updateScreenshot = screenshot => {
      if (this.displayData && this.displayData.screenshot !== screenshot) {
        axios.put(`displays/${this.token}/update`, { screenshot })
        this.displayData.screenshot = screenshot
      }
    }
    this.loopPages = new LoopPages(updateScreenshot)

    if (localStorage.bue_ds_screen_token) {
      this.token = localStorage.bue_ds_screen_token
      const retrieveData = async () => {
        try {
          const res = await axios.get(`displays/${this.token}/public`)
          const { data: dData, display, appSettings } = res.data
          this.displayData = display
          this.loopPages.setSettings(appSettings)
          this.loopPages.setPages(dData)
        } catch (error) {
          if (error.response && error.response.status === 404) {
            localStorage.removeItem('bue_ds_screen_token')
            this.$cookies.remove('bue_ds_screen_token')
            this.$router.push('/install')
          }
        }
      }
      await retrieveData()
      this.retrieveDataInterval = setInterval(async () => {
        await retrieveData()
      }, 20000)
      await this.loopPages.init()
    } else {
      this.$router.push('/install')
    }
  },
}
</script>
