/* eslint-disable newline-before-return */
/* eslint-disable no-unused-vars */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-param-reassign */
import * as moment from 'moment'
import 'moment-timezone'

moment.tz.setDefault('Europe/Berlin')

export default class LoopPages {
  styleSheet
  javascript
  pageDiv
  pageStyle
  pages
  settings
  timeframes
  pageIdActive
  weekday
  standByTimeFrames
  standByOutput
  standByType
  currentShow
  updateScreenshot
  absolutPageIds
  relativPages

  constructor(u) {
    this.styleSheet = document.createElement('style')
    this.styleSheet.setAttribute('id', 'pageStyle')
    document.head.appendChild(this.styleSheet)
    this.javascript = document.createElement('script')
    this.javascript.setAttribute('id', 'pageJavascript')
    this.javascript.setAttribute('src', '/lib/qr-code-styling.js')
    document.body.appendChild(this.javascript)
    this.pageDiv = document.querySelector('#page')
    this.pageStyle = document.querySelector('#pageStyle')
    this.weekday = moment().format('dddd').toLowerCase()
    this.updateScreenshot = u
    this.relativPages = []
  }

  eligebleTimeframe(t) {
    if (!(t.endDate || t.endTime || t.startDate || t.startTime || t.weekDay || t.weekday)) {
      // not configured
      return false
    }
    const endDate = `${t.endDate ?? moment().format('YYYY-MM-DD')} ${t.endTime ?? '23:59:59'}`
    if (moment().isAfter(moment(endDate))) {
      return false
    }
    if (
      !(t.endDate && t.startDate)
      && t.weekday
      && Array.isArray(t.weekday)
      && t.weekday.length
      && !t.weekday.includes(this.weekday)
    ) {
      return false
    }
    if (
      !(t.endDate && t.startDate)
      && t.weekDay
      && Array.isArray(t.weekDay)
      && t.weekDay.length
      && !t.weekDay.includes(this.weekDay)
    ) {
      return false
    }

    return true
  }

  setSettings(obj) {
    this.settings = obj
    this.standByTimeFrames = []
    if (obj) {
      if (obj.global_standby_mode_timeframe) {
        try {
          this.standByTimeFrames = JSON.parse(obj.global_standby_mode_timeframe).filter(t => this.eligebleTimeframe(t)).map(t => {
            const endDate = LoopPages.getEndDate(t)
            const startDate = LoopPages.getStartDate(t)

            return {
              start: moment(startDate).unix(),
              end: moment(endDate).unix(),
            }
          })
        } catch (e) {
          // no log
          console.log(e)
        }

        if (obj.global_standby_mode_background_type) {
          this.standByType = obj.global_standby_mode_background_type
        }
        if (obj.global_standby_mode_background_image) {
          this.standByOutput = obj.global_standby_mode_background_image
        }
      }
    }
  }

  static isPageConfigured(p) {
    if (p.status === 'active' && p.html && ['relativ', 'absolut'].includes(p.visibility)) {
      if (p.visibility === 'relativ' && p.relativ) {
        return true
      }
      if (
        p.visibility === 'absolut'
        && p.absolut_config
        && Array.isArray(p.absolut_config)
        && p.absolut_config.length
      ) {
        return true
      }
    }

    return false
  }

  static getStartDate(t) {
    return `${t.startDate && t.startDate !== '' ? t.startDate : moment().format('YYYY-MM-DD')} ${t.startTime && t.startTime !== '' ? t.startTime : '00:00:00'}`
  }
  static getEndDate(t) {
    return `${t.endDate && t.endDate !== '' ? t.endDate : moment().format('YYYY-MM-DD')} ${t.endTime && t.endTime !== '' ? t.endTime : '23:59:59'}`
  }

  resetRelativePages() {
    this.relativPages = this.relativPages.map(r => {
      r.start = 0
      r.end = 0
      return r
    })
  }

  setPages(data = []) {
    const allPages = data
      .filter(LoopPages.isPageConfigured)
      .sort((a, b) => a.sort - b.sort)
    this.absolutPageIds = []
    let relativePageIds = this.relativPages.map(r => r.id)
    this.timeframes = []
    allPages.forEach(p => {
      if (p.visibility === 'absolut') {
        this.absolutPageIds.push(p.id)
        this.timeframes.push(...(p.absolut_config.filter(t => this.eligebleTimeframe(t)).map(t => {
          const endDate = LoopPages.getEndDate(t)
          const startDate = LoopPages.getStartDate(t)

          return {
            start: moment(startDate).unix(),
            end: moment(endDate).unix(),
            pageId: p.id,
          }
        })))
      } else {
        relativePageIds = relativePageIds.filter(id => id !== p.id)
        const pageInd = this.relativPages.findIndex(o => o.id === p.id)
        if (pageInd < 0) {
          this.relativPages.push({
            start: 0,
            end: 0,
            ...p,
          })
        } else {
          this.relativPages[pageInd] = {
            ...this.relativPages[pageInd],
            ...p,
          }
        }
      }
    })
    if (relativePageIds.length) {
      this.relativPages = this.relativPages.filter(r => !relativePageIds.includes(r.id))
    }

    this.pages = allPages.reduce((all, p) => {
      all[p.id] = p
      return all
    }, {})
    if (
      this.pageIdActive
      && !this.pages[this.pageIdActive]
      && !['standByActiveImage', 'standByActiveColor'].includes(this.pageIdActive)
    ) {
      this.pageIdActive = null
    }
  }

  async pageSet(nextPage = false) {
    const timestamp = moment().unix()
    const standByActive = (this.standByTimeFrames || []).find(t => t.start <= timestamp && t.end >= timestamp)
    let pageId = null
    if (standByActive) {
      this.standByShow()
    } else {
      // check absolut config
      const absolutePage = (this.timeframes || []).find(t => t.start <= timestamp && t.end >= timestamp)

      if (absolutePage && this.pages[absolutePage.pageId]) {
        const page = this.pages[absolutePage.pageId]
        pageId = page.id
        page.page_css = page.page_css || ''
        const contentToShow = page.html + page.css + page.page_css
        if (this.pageIdActive !== page.id || this.currentShow !== contentToShow) {
          this.pageIdActive = page.id
          this.currentShow = contentToShow
          this.pageDiv.innerHTML = page.html
          this.pageStyle.innerHTML = page.css + page.page_css
          this.updateScreenshot(`/screenshot/${page.id}.png`)
          this.runJavascript()
        }
        this.resetRelativePages()
      } else {
        // check relative page
        const activeRelativePage = this.relativPages.find(r => r.id === this.pageIdActive)
        if (activeRelativePage) {
          if (activeRelativePage.end < timestamp || nextPage) {
            this.pageIdActive = null
          } else {
            pageId = activeRelativePage.id
          }
        }
        if (!this.pageIdActive || !activeRelativePage) {
          if (this.relativPages.length) {
            let toBeActiveInd = this.relativPages.findIndex(p => p.start === 0)
            if (toBeActiveInd < 0) {
              this.resetRelativePages()
              toBeActiveInd = 0
            }
            const page = this.relativPages[toBeActiveInd]
            page.start = moment().unix()
            page.end = page.start + page.relativ
            const contentToShow = page.html + page.css + page.page_css
            this.pageIdActive = page.id
            this.currentShow = contentToShow
            this.pageDiv.innerHTML = page.html
            this.pageStyle.innerHTML = page.css + page.page_css
            this.updateScreenshot(`/screenshot/${page.id}.png`)
            this.runJavascript()
            pageId = page.id
            this.relativPages[toBeActiveInd] = page
          }
        }
      }
    }
    if (!pageId) {
      this.standByShow()
    }
  }

  async init() {
    await this.pageSet()
    window.GO_TO_NEXT_PAGE = () => {
      if (this.pageIdActive) {
        if (window.BUE_TERMINE_API_INTERVAL) {
          clearInterval(window.BUE_TERMINE_API_INTERVAL)
        }
        this.pageSet(true)
      }
    }
    setInterval(async () => {
      await this.pageSet()
    }, 1000)
  }

  runJavascript() {
    const scripts = this.pageDiv.getElementsByTagName('script')
    // eslint-disable-next-line no-plusplus
    for (let n = 0; n < scripts.length; n++) {
      // eslint-disable-next-line no-eval
      eval(scripts[n].innerHTML)
    }
    if (window.EVO_CHECK_VIDEOS) {
      window.EVO_CHECK_VIDEOS(this.pageDiv)
    }
  }

  standByShow() {
    if (this.standByType === 'image') {
      if (this.pageIdActive !== 'standByActiveImage' || this.currentShow !== this.standByOutput) {
        this.pageIdActive = 'standByActiveImage'
        this.currentShow = this.standByOutput
        this.pageDiv.innerHTML = `<img style="width: 100vw; height: 100vh;" src="${this.standByOutput}"/>`
        this.updateScreenshot(`/${this.standByOutput.split('/').pop()}`)

        return
      }
    }
    if (this.type === 'color') {
      if (this.pageIdActive !== 'standByActiveColor' || this.currentShow !== this.standByOutput) {
        this.pageIdActive = 'standByActiveColor'
        this.currentShow = this.standByOutput
        this.pageDiv.innerHTML = `<div style="width: 100vw; height: 100vh; background-color:${this.standByOutput}" ></div>`
        this.updateScreenshot(this.standByOutput)
      }
    }
    this.resetRelativePages()
  }
}
